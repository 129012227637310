backbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 3px;
  letter-spacing: 1px;
  transition: all 0.2s linear;
  cursor: pointer;
  border: none;

}

backbutton>svg {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 20px;
  transition: all 0.4s ease-in;
}

backbutton:hover>svg {
  font-size: 1.2em;
  transform: translateX(-5px);
}

backbutton:hover {

  transform: translateY(-2px);
}