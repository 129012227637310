html {
  background: #f2f2f2;
}

.scroll-container,
.scroll-area {
  max-width: 'fit';
  height: 800px;
  font-size: 60px;
}

.scroll-container {
  overflow: auto;
  -webkit-scroll-snap-type: y mandatory;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}

.scroll-area {
  scroll-snap-align: start;
}

.scroll-container,
.scroll-area {
  margin: 0 auto;
}

.scroll-area {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}