.loading1 svg polyline {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.loading1 svg polyline#back {
    fill: none;
    stroke: #bbbbbba2;
}

.loading1 svg polyline#front {
    fill: none;
    stroke: #ffffff;
    stroke-dasharray: 57, 171;
    stroke-dashoffset: 228;
    animation: dash_682 1.4s linear infinite;
}

@keyframes dash_682 {
    72.5% {
        opacity: 0;
    }

    to {
        stroke-dashoffset: 0;
    }
}



  