@keyframes moveAndFlash {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    50% {
        transform: translateX(10px);
        opacity: 0.7;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.text-line {
    animation: moveAndFlash 2s infinite;
}