.signupBtn {
    width: 240px;
    height: 40px;
    border-radius: 30px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 13px;
    gap: 9px;
    color: #0b436f;
    background: #ffffff;
    position: relative;
    cursor: pointer;
    font-weight: 600;
}

.arrow {
    position: absolute;
    right: 7.5px;
    background-color: #0b436f;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    transition: all .5s ease;
}

.signupBtn:hover .arrow {
    width: calc(240px - (7.5px)*2);
}