.animated-svg {
    animation: translateYAndRotate 8s linear infinite;
}

@keyframes translateXAndRotate {
    0% {
        transform: translateY(0) rotate(0);
    }

    25% {
        transform: translateY(20px) rotate(90deg);
    }

    50% {
        transform: translateY(0) rotate(180deg);
    }

    75% {
        transform: translateY(-20px) rotate(270deg);
    }

    100% {
        transform: translateY(0) rotate(360deg);
    }
}