.arrow-container {
    position: relative;
    width: 40px;
    height: 40px;
    animation: moveChevron 2s infinite;
}

.arrow-down {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    /* Added rotate(180deg) */
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    clip-path: polygon(0 50%, 0 50%, 50% 0, 100% 50%);
}


@keyframes moveChevron {
    0% {
        top: 0;
    }

    50% {
        top: 20px;
    }

    100% {
        top: 0;
    }
}