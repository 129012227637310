body {
  background-color: #02a09e;
}

.app {
  font-family: Arial, sans-serif;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 4px 6px rgb(6, 205, 192);
}

h1 {
  font-size: 2rem;
  color: rgb(255, 255, 255);
  margin-bottom: 1rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

fieldset {
  border: none;
  padding: 10px 0;
}

legend {
  font-size: 2.0rem;
  color: rgb(255, 255, 255);
  padding: 0 5px;
  text-align: left;
}

label {
  display: flex;

  font-size: 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin-bottom: 5px;
}

input[type='checkbox'] {
  margin-right: 5px;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  background-color: transparent;
}

p {
  font-size: 2.2rem;
  color: rgb(255, 255, 255);
  margin-top: 1rem;
  line-height: 1.5;
  text-align: center;
}


a {
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  margin-top: 1rem;
  line-height: 1.5;
  text-align: left;
}

.category-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: rgb(255, 255, 255);
}

td {
  border: 1px solid rgb(255, 255, 255);
  padding: 10px;
  text-align: left;
}

td:first-child {
  font-weight: bold;
  width: 50%;
}

td:last-child {
  width: 50%;
}

name {
  font-size: 2.2rem;
  color: rgb(255, 255, 255);
  margin-top: 1rem;
  line-height: 1.5;
  text-align: center;
}

#footer {
  position: center;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 40px;
  background: rgb(0, 95, 100);
}

footertext {
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  margin-top: 0.5rem;
  line-height: 1.0;
  text-align: center;
}

.category-box1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
}

table1 {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: rgb(233, 233, 233);
}


img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}


.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}



